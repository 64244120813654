let RatingStars = {
  mounted() {
    this.initializeElements();
    this.addEventListeners();
    this.updateStars(this.currentRating);
  },

  initializeElements() {
    this.stars = this.el.querySelectorAll("label");
    this.inputs = this.el.querySelectorAll("input");
    this.currentRating = parseInt(this.el.dataset.star) || 0;
    this.optimisticRating = this.currentRating;
  },

  addEventListeners() {
    this.stars.forEach((star, index) => {
      star.addEventListener("mouseover", () => this.handleMouseOver(index));
      star.addEventListener("mouseout", () => this.handleMouseOut());
      star.addEventListener("click", () => this.handleClick(index));
    });
  },

  handleMouseOver(index) {
    this.updateStarsAppearance(index);
  },

  handleMouseOut() {
    this.updateStars(this.optimisticRating);
  },

  handleClick(index) {
    this.inputs[index].checked = true;
    this.optimisticRating = index + 1;
    this.updateStars(this.optimisticRating);
  },

  updateStarsAppearance(index) {
    this.stars.forEach((s, i) => {
      s.classList.toggle("text-yellow-400", i <= index);
    });
  },

  updated() {
    const newRating = parseInt(this.el.dataset.star) || 0;
    if (newRating !== this.currentRating) {
      this.currentRating = newRating;
      this.optimisticRating = this.currentRating;
      this.updateStars(this.currentRating);
    }
  },

  updateStars(rating) {
    this.updateStarsAppearance(rating - 1);
  },
};

export default RatingStars;

// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html";
// Establish Phoenix Socket and LiveView configuration.
import { Socket } from "phoenix";
import { LiveSocket } from "phoenix_live_view";

import { hookSvelte } from "./hooker";
import { Editor } from "./hooks/editor";

import { loadStripe } from "@stripe/stripe-js";
import topbar from "topbar";
import { FormSubmit } from "./hooks/FormSubmit";
import { Chromium } from "./hooks/Chromium";
import { ColorPicker } from "./hooks/ColorPicker";
import { Copy } from "./hooks/Copy";
import { Tip } from "./hooks/Tip";
import { SortableInputsFor } from "./hooks/SortableInputsFor";
import { CtrlEnterSubmit } from "./hooks/CtrlEnterSubmit";
import { VideoPlayer } from "./hooks/VideoPlayer";
import { DatePicker } from "./hooks/DatePicker";
import { ScrollObserver } from "./hooks/ScrollObserver";

import * as Sentry from "@sentry/browser";
import RatingStars from "./hooks/Rating";

Sentry.init({
  dsn: "https://51e9422a6a58e74cb9cb54175a7617a6@o4505764677025792.ingest.us.sentry.io/4505764680040448",

  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/activeinterview\.com\//],
});

const csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

const Hooks = {
  Editor,
  Chromium,
  ColorPicker,
  Copy,
  SortableInputsFor,
  CtrlEnterSubmit,
  FormSubmit,
  VideoPlayer,
  DatePicker,
  ScrollObserver,
  Tip,
  RatingStars,
  IntroRecorder: hookSvelte("intro_recorder"),
  Recorder: hookSvelte("recorder"),
  Setuper: hookSvelte("setuper"),
  VideoRecorder: hookSvelte("video_recorder"),
};

const Uploaders = {};

Uploaders.S3 = (entries, onViewError) => {
  for (const entry of entries) {
    const { url } = entry.meta;
    const xhr = new XMLHttpRequest();
    onViewError(() => xhr.abort());
    xhr.onload = () =>
      xhr.status === 204 ? entry.progress(100) : entry.error();
    xhr.onerror = () => entry.error();
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        if (percent <= 100) {
          entry.progress(percent);
        }
      }
    });

    xhr.open("PUT", url, true);
    xhr.send(entry.file);
  }
};

const liveSocket = new LiveSocket("/live", Socket, {
  hooks: Hooks,
  uploaders: Uploaders,
  params: { _csrf_token: csrfToken },
});

// Show progress bar on live navigation and form submits
topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" });
window.addEventListener("phx:page-loading-start", (_info) => topbar.show(500));
window.addEventListener("phx:page-loading-stop", (_info) => topbar.hide());

window.addEventListener("mouseover", (e) => {
  // if the event's target has the xphx-mouseover attribute,
  // execute the commands on that element
  if (e.target.matches("[xphx-mouseover]")) {
    liveSocket.execJS(e.target, e.target.getAttribute("xphx-mouseover"));
  }
});

window.addEventListener("mouseout", (e) => {
  // if the event's target has the xphx-mouseout attribute,
  // execute the commands on that element
  if (e.target.matches("[xphx-mouseout]")) {
    liveSocket.execJS(e.target, e.target.getAttribute("xphx-mouseout"));
  }
});

window.addEventListener("phx:copy", (event) => {
  navigator.clipboard.writeText(event.target.dataset.content);
  const oldContent = event.target.innerHTML;
  event.target.innerHTML = "Copied!";
  setTimeout(() => {
    event.target.innerHTML = oldContent;
  }, 3000);
});

window.addEventListener("hirehawk:toggle-text", (event) => {
  for (const klass of event.detail.classes_to_toggle) {
    event.target.classList.toggle(klass);
  }
});

window.addEventListener("phx:scroll-to-top", ({ detail: { selector } }) => {
  if (selector) {
    document.querySelector(selector).scrollTo(0, 0);
  } else {
    window.scrollTo(0, 0);
  }
});

window.addEventListener("phx:redirect_to_stripe", async (e) => {
  const { sessionId, publicKey } = e.detail;
  const stripe = await loadStripe(publicKey);
  stripe
    .redirectToCheckout({
      sessionId,
    })
    .then((result) => {
      if (result.error) {
        // var displayError = document.getElementById("error-message"); %>
        // displayError.textContent = result.error.message;
        console.log(result.error);
      }
    });
});

// connect if there are any LiveViews on the page
liveSocket.connect();

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket;

const loadModules = async () => {
  const [
    { Editor: TEditor },
    { default: TextAlign },
    { default: Link },
    { default: StarterKit },
    { default: Placeholder },
  ] = await Promise.all([
    import("@tiptap/core"),
    import("@tiptap/extension-text-align"),
    import("@tiptap/extension-link"),
    import("@tiptap/starter-kit"),
    import("@tiptap/extension-placeholder"),
  ]);

  return { TEditor, TextAlign, Link, StarterKit, Placeholder };
};
export const Editor = {
  editor: null,
  setLink() {
    const previousUrl = this.editor.getAttributes("link").href;
    const url = window.prompt("Enter a url", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    this.editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  },
  async mounted() {
    const { TEditor, TextAlign, Link, StarterKit, Placeholder } =
      await loadModules();

    let initialContent = this.el.dataset.initialContent || "";
    let placeholder = this.el.dataset.placeholder || "";
    let inputElement = this.el.querySelector(`#${this.el.dataset.inputId}`);
    let editorElement = this.el.querySelector(`#${this.el.dataset.editorId}`);
    let setContentEventName =
      this.el.dataset.setContentEventName || "set_content";

    this.editor = new TEditor({
      element: editorElement,
      onUpdate: ({ editor }) => {
        inputElement.value = editor.getHTML();
      },
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Link.configure({
          openOnClick: false,
        }),
      ],
      content: initialContent,
      editorProps: {
        attributes: {
          class:
            "prose prose-md max-w-none  prose-zinc prose-headings:font-medium prose-strong:font-medium m-4 focus:outline-none",
        },
      },
    });

    this.handleEvent(setContentEventName, ({ text }) => {
      this.editor.commands.setContent(text);
      inputElement.value = text;
    });

    this.el.addEventListener("editor_bold", (_event) => {
      this.editor.chain().focus().toggleBold().run();
    });

    this.el.addEventListener("editor_italic", (_event) => {
      this.editor.chain().focus().toggleItalic().run();
    });

    this.el.addEventListener("editor_h_1", (_event) => {
      this.editor.chain().focus().toggleHeading({ level: 1 }).run();
    });
    this.el.addEventListener("editor_h_2", (_event) => {
      this.editor.chain().focus().toggleHeading({ level: 2 }).run();
    });
    this.el.addEventListener("editor_unordered", (_event) => {
      this.editor.chain().focus().toggleBulletList().run();
    });
    this.el.addEventListener("editor_ordered", (_event) => {
      this.editor.chain().focus().toggleOrderedList().run();
    });
    this.el.addEventListener("editor_align_left", (_event) => {
      this.editor.chain().focus().setTextAlign("left").run();
    });
    this.el.addEventListener("editor_align_center", (_event) => {
      this.editor.chain().focus().setTextAlign("center").run();
    });
    this.el.addEventListener("editor_align_right", (_event) => {
      this.editor.chain().focus().setTextAlign("right").run();
    });
    this.el.addEventListener("editor_link", (_event) => {
      this.setLink();
    });
  },
  destroyed() {
    this.editor.destroy();
  },
};

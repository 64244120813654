export const FormSubmit = {
  mounted() {
    const formId = this.el.dataset.formId;
    this.el.addEventListener("click", () => {
      document
        .getElementById(formId)
        .dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true }),
        );
    });
  },
};

import Hls from "hls.js";
import Plyr from "plyr";

export const VideoPlayer = {
  videoElement: null,
  transcriptionTrackElement: null,
  player: null,
  hls: null,

  async initializeVideoSource(src) {
    if (src.includes(".m3u8")) {
      await this.initializeHLS(src);
    } else {
      this.videoElement.src = src;
    }
  },

  async initializeHLS(src) {
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.loadSource(src);
      this.hls.attachMedia(this.videoElement);
    } else if (this.videoElement.canPlayType("application/vnd.apple.mpegurl")) {
      this.videoElement.src = src;
    }
  },

  async initializePlyr() {
    this.player = new Plyr(this.videoElement, {
      captions: { active: true, update: true },
    });
  },

  async mount_player() {
    const { usePlyr, src, transcription } = this.el.dataset;

    await this.initializeVideoSource(src);

    if (this.transcriptionTrackElement) {
      this.transcriptionTrackElement.src = transcription;
    }

    if (usePlyr !== undefined) {
      await this.initializePlyr();
    }
  },

  async mounted() {
    this.videoElement = this.el.querySelector("video");
    this.transcriptionTrackElement = this.el.querySelector("track");
    await this.mount_player();

    this.setupEventListeners();
  },

  setupEventListeners() {
    this.el.addEventListener("video-go-to", this.handleVideoGoTo.bind(this));
    this.videoElement.addEventListener("error", this.handleVideoError);
  },

  handleVideoGoTo({ detail }) {
    if (this.player) {
      this.player.currentTime = detail.start_timestamp / 1000;
      this.player.play();
    }
  },

  handleVideoError(error) {
    console.error(error);
  },

  updated() {
    this.cleanup();
    this.mounted();
  },

  cleanup() {
    if (this.hls) {
      this.hls.destroy();
    }
    if (this.player) {
      this.player.destroy();
    }
  },
};

export const ColorPicker = {
  async mounted() {
    const { init, coloris } = await import("@melloware/coloris");
    init();
    coloris({
      el: `#${this.el.id}`,
      // parent: `#${this.el.parentElement.id}`
    });
  },
};

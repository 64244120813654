export const Tip = {
  async mounted() {
    await this.initTippy();
  },
  async updated() {
    if (this.tippyInstance) {
      this.tippyInstance.setContent(this.el.dataset.tip);
    } else {
      await this.initTippy();
    }
  },
  async initTippy() {
    const { default: tippy } = await import("tippy.js");
    if (!this.el.dataset.tip) {
      console.warn(
        "No tip provided for element :",
        this.el,
        "Make sure you use data-tip",
      );
      return;
    }
    this.tippyInstance = tippy(this.el, {
      content: this.el.dataset.tip,
      placement: this.el.dataset.placement || "top",
      allowHTML: true,
    });
  },
};

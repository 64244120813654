export const Copy = {
  async mounted() {
    const { default: tippy } = await import("tippy.js");
    if (this.el.dataset.tip) {
      tippy(this.el, {
        content: this.el.dataset.tip,
        placement: this.el.dataset.placement || "top",
        allowHTML: true,
      });
    }
    this.el.addEventListener("click", () => {
      navigator.clipboard.writeText(this.el.dataset.content);
      const tip = tippy(this.el, {
        content: "Copied!",
        trigger: "manual",
        placement: "top",
        duration: [300, 250],
      });
      tip.show();
      setTimeout(() => {
        tip.hide();
      }, 2000);
    });
  },
};

export const ScrollObserver = {
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const id = entry.target.getAttribute("id");
        if (entry.intersectionRatio > 0) {
          document
            .querySelector(`.toc li a[href="#${id}"]`)
            .parentElement.classList.add("active");
        } else {
          document
            .querySelector(`.toc li a[href="#${id}"]`)
            .parentElement.classList.remove("active");
        }
      }
    });

    // Track all sections that have an `id` applied
    for (const heading of document.querySelectorAll(
      "h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]",
    )) {
      observer.observe(heading);
    }
  },
};

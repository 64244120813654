export const DatePicker = {
  async mounted() {
    const { default: DatePicker } = await import(
      "flowbite-datepicker/Datepicker"
    );
    new DatePicker(this.el, { autohide: true, format: "yyyy-mm-dd" });
  },
  updated() {
    this.mounted();
  },
};

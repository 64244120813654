export function hookSvelte(cp) {
  return {
    async mounted() {
      const [mounter, component] = await Promise.all([
        import("./svelte_mounter.js"),
        import(`./svelte/${cp}.svelte`),
      ]);

      this.mounter = mounter;

      const { mountSvelte } = this.mounter;
      const { default: Component } = component;
      this.Component = Component;
      this.props = {
        ...this.el.dataset,
        live: this,
      };
      this.instance = mountSvelte(this.el, this.Component, this.props);
    },
    updated() {
      const { updateSvelte } = this.mounter;
      this.props = { ...this.props, ...this.el.dataset };
      updateSvelte(this.instance, this.props);
    },
    destroyed() {},
  };
}

export const SortableInputsFor = {
  async mounted() {
    const { default: Sortable } = await import("sortablejs");
    const group = this.el.dataset.group;
    new Sortable(this.el, {
      group: group ? { name: group, pull: true, put: true } : undefined,
      animation: 150,
      ghostClass: "opacity-10",
      handle: "[data-handle]",
      forceFallback: true,
      onEnd: (e) => {
        this.el
          .closest("form")
          .querySelector("input")
          .dispatchEvent(new Event("input", { bubbles: true }));
      },
    });
  },
};
